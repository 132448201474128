<template>
  <div>
    <div>You don`t have access to this page. Go back</div>
  </div>
</template>

<script>
export default {
  name: 'NoAccess'
}
</script>
